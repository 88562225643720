import Honeybadger from "@honeybadger-io/js";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import humps, { camelizeKeys } from "humps";
import i18next from "i18next";

import { useAuthStore } from "stores/auth";
import { useLocationStore } from "stores/location";

import {
  AddPriceLabel,
  CashRegisterCreate,
  Category,
  CategoryReport,
  CombiDeal,
  CombiDealsOverview,
  CreateCombiDeal,
  CreatePaymentMethod,
  DataResponse,
  DepositOption,
  DynamicPricing,
  Environment,
  GetPaymentMethodResponse,
  LedgerAccount,
  LedgerAccountCreate,
  Pagination,
  PaymentMethod,
  PriceLabelSettings,
  PriceLabelConfig,
  Product,
  ProductResult,
  Property,
  PropertyCreate,
  Receipt,
  ReceiptState,
  Report,
  ReviewList,
  Shop,
  ShopCreate,
  Turnover,
  UpdatePaymentMethod,
  UpdatePriceLabel,
  User,
  Vat,
  VoucherReport,
  DynamicDate,
} from "types";
import { exportCsv } from "utils/csvExport";
import { dateRangeToString, formatUtcDateWithTimeZone } from "utils/dates";

const getApiRoute = (route: string) =>
  useLocationStore.getState().currentLocation?.id
    ? `/properties/${useLocationStore.getState().currentLocation!.id}${route}`
    : route;

export const API_ROOT =
  import.meta.env?.VITE_ENV === Environment.production
    ? "https://api.valk-togo.nl"
    : "https://valktogo-api-staging.valkdigitalservices.nl";

export const BASE_URL = `${API_ROOT}/backoffice/api/v1`;

export const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

client.interceptors.response.use(
  (response) => {
    if (response.data && response.headers["content-type"]?.includes("application/json")) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      useAuthStore.getState().setIsAuthenticated(false);
    }

    return Promise.reject(error);
  }
);

client.interceptors.request.use((config) => {
  const newConfig = { ...config };

  newConfig.headers["Accept-Language"] = i18next.language;
  if (config.params) newConfig.params = humps.decamelizeKeys(config.params);
  if (config.data) newConfig.data = humps.decamelizeKeys(config.data);
  return newConfig;
});

const notifyHoneybadgerOfAxiosError = (e: AxiosError) => {
  Honeybadger.beforeNotify((notice) => {
    if (!notice) return;
    notice.context = {
      request: JSON.stringify(e.request),
      response: JSON.stringify(e.response),
    };
  });
  Honeybadger.notify({ name: "Axios Error", message: e.message });
};

const handleErrors = (e: AxiosError) => {
  notifyHoneybadgerOfAxiosError(e);
  throw e;
};

const requests = {
  get: async (url: string, config?: AxiosRequestConfig) => {
    try {
      const response = await client.get(url, config);
      return response.data;
    } catch (err) {
      handleErrors(err as AxiosError);
    }
  },
  post: async (url: string, data?: object, config?: AxiosRequestConfig) => {
    try {
      const response = await client.post(url, data, config);
      return response.data;
    } catch (err) {
      handleErrors(err as AxiosError);
    }
  },
  put: async (url: string, data?: object, config?: AxiosRequestConfig) => {
    try {
      const response = await client.put(url, data, config);
      return response.data;
    } catch (err) {
      handleErrors(err as AxiosError);
    }
  },
  delete: async (url: string, config?: AxiosRequestConfig) => {
    try {
      const response = await client.delete(url, config);
      return response.data;
    } catch (err) {
      handleErrors(err as AxiosError);
    }
  },
  export: async (url: string, fallback: string, body?: object) => {
    try {
      await client.post(url, body, { responseType: "blob" }).then((response) => {
        exportCsv(
          response.data,
          getHeaderProp(response, "Content-Disposition") ?? `attachment; filename=${fallback}.csv`
        );
      });
    } catch (err) {
      handleErrors(err as AxiosError);
    }
  },
};

const getPeriodBody = (dates?: Date[]) => {
  if (!dates) return {};
  return {
    startAt: formatUtcDateWithTimeZone(dates[0]),
    endAt: formatUtcDateWithTimeZone(dates[1]),
  };
};

class ApiService {
  client: typeof requests;

  constructor() {
    this.client = requests;
  }

  users = {
    login: async (email: string, password: string) =>
      this.client.post("/users/log_in", { user: { email, password } }),
    logout: async () => this.client.delete("/users/log_out"),
    sendEmailSetPassword: async (email: string) =>
      this.client.post("/users/request_password_reset", { email: email }),
    setPassword: async (token: string, password: string, passwordConfirmation: string) =>
      this.client.post("/users/set_password", {
        token,
        password,
        passwordConfirmation,
      }),
    createAppToken: async (): Promise<DataResponse<string>> =>
      this.client.post("/users/me/app_token"),
  };

  account = {
    getAll: async (): Promise<DataResponse<User[]>> => this.client.get(getApiRoute("/users")),
    getById: async (id: string): Promise<DataResponse<User>> =>
      this.client.get(getApiRoute(`/users/${id}`)),
    getCurrentUser: async (): Promise<DataResponse<User>> => this.client.get("/users/me"),
    create: async (data: User) => this.client.post(getApiRoute("/users"), data),
    update: async (id: string, data: any) => this.client.put(getApiRoute(`/users/${id}`), data),
    delete: async (id: string) => this.client.delete(getApiRoute(`/users/${id}`)),
  };

  cashRegister = {
    getAll: async () => this.client.get(getApiRoute("/cash_registers")),
    getById: async (id: string) =>
      this.client.get(getApiRoute("/cash_registers"), {
        params: { shopId: id },
      }),
    create: async (data: CashRegisterCreate) =>
      this.client.post(getApiRoute("/cash_registers"), data),
    update: async (id: string, data: CashRegisterCreate) =>
      this.client.put(getApiRoute(`/cash_registers/${id}`), data),
    delete: async (id: string) => this.client.delete(getApiRoute(`/cash_registers/${id}`)),
  };

  category = {
    getAll: async (): Promise<DataResponse<Category[]>> =>
      this.client.get(getApiRoute("/categories")),
    getById: async (id: string): Promise<DataResponse<Category>> =>
      this.client.get(getApiRoute(`/categories/${id}`)),
    create: async (data: any) => this.client.post(getApiRoute("/categories"), data),
    update: async (id: string, data: any) =>
      this.client.put(getApiRoute(`/categories/${id}`), data),
    delete: async (id: string) => this.client.delete(getApiRoute(`/categories/${id}`)),
  };

  group = {
    getById: async (id: string) => this.client.get(`/groups/${id}`),
  };

  product = {
    getAll: async (categoryId?: number, pagination?: Pagination): Promise<ProductResult> =>
      this.client.get(getApiRoute("/products"), {
        params: {
          ...(categoryId ? { categoryId } : {}),
          ...(pagination ? { pagination } : {}),
        },
      }),
    getByLedgerId: async (ledgerAccountId: number): Promise<DataResponse<Product[]>> =>
      this.client.get(getApiRoute("/products"), {
        params: { ledgerAccountId },
      }),
    getById: async (id: number): Promise<DataResponse<Product>> =>
      this.client.get(getApiRoute(`/products/${id}`)),
    getDepositOptions: async (): Promise<{ depositCentsOptions: DepositOption[] }> =>
      this.client.get(getApiRoute("/products/deposit_options")),
    create: async (data: any) => this.client.post(getApiRoute("/products"), data),
    update: async (id: number, data: any) => this.client.put(getApiRoute(`/products/${id}`), data),
    delete: async (id: number) => this.client.delete(getApiRoute(`/products/${id}`)),
    export: async (): Promise<void> =>
      this.client.export(
        getApiRoute("/products/export"),
        `${i18next.t(
          "products.exportAll",
          useLocationStore.getState().currentLocation?.name ?? ""
        )}`
      ),
  };

  combiDeal = {
    getAll: async (): Promise<DataResponse<CombiDealsOverview[]>> =>
      this.client.get("/combi_deals"),
    getById: async (id: string): Promise<DataResponse<CombiDeal>> =>
      this.client.get(`/combi_deals/${id}`),
    create: async (data: CreateCombiDeal) => this.client.post("/combi_deals", { combiDeal: data }),
    update: async (id: string, data: Partial<CombiDeal>) =>
      this.client.put(`/combi_deals/${id}`, { combiDeal: data }),
    delete: async (id: string) => this.client.delete(`/combi_deals/${id}`),
  };

  property = {
    getAll: async (): Promise<DataResponse<Property[]>> => this.client.get("/properties"),
    getById: async (id: number): Promise<DataResponse<Property>> =>
      this.client.get(`/properties/${id}`),
    create: async (data: Partial<PropertyCreate>) =>
      this.client.post("/properties", { property: data }),
    delete: async (id: number) => this.client.delete(`/properties/${id}`),
    update: async (id: number, data: Partial<PropertyCreate>) =>
      this.client.put(`/properties/${id}`, { property: data }),
    addPriceLabel: async (id: number, data: AddPriceLabel) =>
      this.client.post(`/properties/${id}/price_labels`, data),
  };
  vat = {
    getAll: async (): Promise<DataResponse<Vat[]>> => this.client.get(getApiRoute("/vats")),
    getById: async (id?: number): Promise<DataResponse<Vat>> =>
      this.client.get(`/vats/${id ? id : ""}`),
  };

  ledgerAccount = {
    getAll: async (): Promise<DataResponse<LedgerAccount[]>> =>
      this.client.get(getApiRoute("/ledger_accounts")),
    getById: async (id: number): Promise<DataResponse<LedgerAccount>> =>
      this.client.get(getApiRoute(`/ledger_accounts/${id}`)),
    create: async (data: LedgerAccountCreate) =>
      this.client.post(getApiRoute("/ledger_accounts"), data),
    update: async (id: number, data: LedgerAccountCreate) =>
      this.client.put(getApiRoute(`/ledger_accounts/${id}`), data),
    delete: async (id: number) => this.client.delete(getApiRoute(`/ledger_accounts/${id}`)),
  };

  paymentMethod = {
    getAll: async (): Promise<DataResponse<GetPaymentMethodResponse>> =>
      this.client.get(getApiRoute("/payment_method")),
    create: async (data: {
      paymentMethod: CreatePaymentMethod;
    }): Promise<DataResponse<PaymentMethod>> =>
      this.client.post(getApiRoute("/payment_method"), data),
    update: async (
      id: number,
      data: { paymentMethod: UpdatePaymentMethod }
    ): Promise<DataResponse<PaymentMethod>> =>
      this.client.put(getApiRoute(`/payment_method/${id}`), data),
    delete: async (id: number) => this.client.delete(getApiRoute(`/payment_method/${id}`)),
  };

  shop = {
    getAll: async (): Promise<DataResponse<Shop[]>> => {
      return this.client.get(getApiRoute("/shops"));
    },
    getById: async (id: string): Promise<DataResponse<Shop>> =>
      this.client.get(getApiRoute(`/shops/${id}`)),
    create: async (data: ShopCreate) => this.client.post(getApiRoute("/shops"), data),
    update: async (id: string, data: ShopCreate) =>
      this.client.put(getApiRoute(`/shops/${id}`), data),
    delete: async (id: string) => this.client.delete(getApiRoute(`/shops/${id}`)),
  };

  qrcode = {
    export: async (amount?: number): Promise<DataResponse<string[]>> =>
      this.client.post(getApiRoute("/qr_codes"), {
        ...(amount ? { amount } : {}),
      }),
  };

  receipt = {
    getAll: async (
      state?: ReceiptState,
      dates?: Date[],
      shopId?: number,
      hasReview?: boolean
    ): Promise<DataResponse<Receipt[]>> =>
      this.client.get(getApiRoute("/receipts"), {
        params: {
          ...(state ? { state } : {}),
          ...getPeriodBody(dates),
          ...(shopId ? { shopId } : {}),
          ...(hasReview ? { hasReview } : {}),
        },
      }),
    reviews: async (dates?: Date[], shopId?: number): Promise<DataResponse<ReviewList>> =>
      this.client.get(getApiRoute("/receipts/reviews"), {
        params: {
          ...getPeriodBody(dates),
          ...(shopId ? { shopId } : {}),
        },
      }),
    getById: async (id: string): Promise<DataResponse<Receipt>> =>
      this.client.get(getApiRoute(`/receipts/${id}`)),
    deliver: async (id: string, email: string): Promise<string> =>
      this.client.put(getApiRoute(`/receipts/${id}/deliver`), { email }),
  };

  transaction = {
    export: async (dates: Date[], shop?: number, state?: ReceiptState): Promise<void> =>
      this.client.export(
        getApiRoute("/transactions/export"),
        `${i18next.t("transactions.transactions")}_${dateRangeToString(dates)}`,
        {
          ...getPeriodBody(dates),
          ...(shop ? { shopId: shop } : {}),
          ...(state ? { state } : {}),
        }
      ),
  };
  turnover = {
    getAll: async (dates?: Date[]): Promise<DataResponse<Turnover>> =>
      this.client.get(getApiRoute("/turnover"), {
        params: getPeriodBody(dates),
      }),
    export: async (dates: Date[]): Promise<void> =>
      this.client.export(
        getApiRoute("/turnover/export"),
        `${i18next.t("administration.turnover")}_${dateRangeToString(dates)}`,
        getPeriodBody(dates)
      ),
  };
  reporting = {
    getAll: async (dates?: Date[]): Promise<DataResponse<Report>> =>
      this.client.get(getApiRoute("/reporting"), {
        params: getPeriodBody(dates),
      }),
    vouchers: async (dates?: Date[]): Promise<DataResponse<VoucherReport>> =>
      this.client.get(getApiRoute("/reporting/vouchers"), {
        params: getPeriodBody(dates),
      }),
    categories: async (
      categoryId: Category["id"],
      dates?: Date[]
    ): Promise<DataResponse<CategoryReport>> =>
      this.client.get(getApiRoute(`/reporting/categories/${categoryId}`), {
        params: getPeriodBody(dates),
      }),
  };

  priceLabels = {
    getAll: (shop: Shop["id"]): Promise<DataResponse<PriceLabelSettings>> =>
      this.client.get(getApiRoute("/price_labels"), {
        params: { shopId: shop },
      }),
    assignRandomly: async (data: { shopId: Shop["id"] }) =>
      this.client.post(getApiRoute("/price_labels/pair_products"), {
        ...data,
        random: true,
      }),
    update: async (data: UpdatePriceLabel) =>
      this.client.post(getApiRoute("/price_labels/pair_product"), data),
    config: {
      get: async (shop: Shop["id"]): Promise<DataResponse<PriceLabelConfig>> =>
        this.client.get(getApiRoute("/price_labels/config"), {
          params: { shopId: shop },
        }),
      setup: async (shop: Shop["id"]) =>
        this.client.post(getApiRoute("/price_labels/config"), {
          shopId: shop,
          configure: true,
        }),
    },
  };

  dynamicHours = {
    get: async (): Promise<DataResponse<DynamicPricing>> =>
      this.client.get(getApiRoute("/dynamic_hours")),
    update: async (data: DynamicPricing) =>
      this.client.put(getApiRoute("/dynamic_hours"), { dynamicPricing: data }),
  };
  dynamicDate = {
    getAll: async (minDatetime?: string): Promise<DataResponse<DynamicDate[]>> =>
      this.client.get(getApiRoute("/dynamic_dates"), { params: { minDatetime } }),
    create: async (data: Omit<DynamicDate, "id">): Promise<DataResponse<DynamicDate>> =>
      this.client.post(getApiRoute("/dynamic_dates"), { DynamicDate: data }),
    update: async (id: number, data: DynamicDate): Promise<DataResponse<DynamicDate>> =>
      this.client.put(getApiRoute(`/dynamic_dates/${id}`), {
        DynamicDate: data,
      }),
    delete: async (id: number) => this.client.delete(getApiRoute(`/dynamic_dates/${id}`)),
  };
}

const getHeaderProp = (response: AxiosResponse, headerProp: string) => {
  return response.headers[headerProp.toLowerCase()];
};
export default new ApiService();
