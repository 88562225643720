import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, NavLink, Text } from "@mantine/core";
import { AiFillShop } from "react-icons/ai";
import {
  HiChartBar,
  HiCreditCard,
  HiDocumentReport,
  HiOutlineCube,
  HiOutlineLogout,
  HiUserCircle,
  HiStar,
} from "react-icons/hi";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "./Navbar.module.scss";
import useLogout from "hooks/useLogout";
import { useLocationStore } from "stores/location";
import PermissionWrapper from "utils/PermissionWrapper";
import { useUiStorage } from "stores/ui";
import { DefaultTheme, Permissions, Theme } from "types";
import { isBrowser, isTablet } from "react-device-detect";
import packageJson from "../../../package.json";
import useCurrentUser from "utils/currentUser";
import useLinkBuilder from "hooks/useLinkBuilder";
import { updatePropertyTheme } from "utils/propertyThemes";
import { MdQrCode2 } from "react-icons/md";
import { HiTicket } from "react-icons/hi2";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { usePropertyQuery } from "hooks/queries/usePropertyQuery";

const Navbar: FC = () => {
  const { t } = useTranslation();
  const { isNavFolded } = useUiStorage();
  const logout = useLogout();
  const { currentLocation } = useLocationStore();

  return (
    <div className={styles["navbar"]}>
      <NavBarLocation />
      {(isBrowser || isTablet) && <ButtonFoldSidebar />}
      <NavLink
        className={classNames(styles["navbar-logout"], {
          [styles["navbar-logout-location"]]: !!currentLocation,
        })}
        label={t("menu.logout")}
        component={Link}
        to="#"
        data-cy="navigation-item-logout"
        leftSection={<HiOutlineLogout size={24} />}
        onClick={logout}
        c="white"
        p="lg"
      />
      <Text c="white" fz="xs" p="sm" className={styles["version-indicator"]}>
        {!isNavFolded ? `${t("version")} ${packageJson.version}` : `${packageJson.version}`}
      </Text>
    </div>
  );
};

const ButtonFoldSidebar: FC = () => {
  const { t } = useTranslation();
  const { foldSidebar, isNavFolded } = useUiStorage();
  const { currentLocation } = useLocationStore();

  const onToggle = () => {
    foldSidebar(!isNavFolded);
  };

  return (
    <Box mt="lg">
      <NavLink
        className={classNames(styles["navbar-link"], {
          [styles["navbar-link-location"]]: !!currentLocation,
        })}
        onClick={onToggle}
        label={t("fold")}
        data-cy="navigation-item-fold"
        c="white"
        p="lg"
        leftSection={isNavFolded ? <RiMenuUnfoldLine size={24} /> : <RiMenuFoldLine size={24} />}
      />
    </Box>
  );
};

interface ToGoNavLinkProps {
  children?: React.ReactNode;
  isAdmin?: boolean;
  isParent?: boolean;
  label: string;
  subPath: string;
  parentPath?: string;
  icon?: ReactNode;
  testId?: string;
  isChild?: boolean;
}

const ToGoNavLink: FC<ToGoNavLinkProps> = ({
  children,
  isParent,
  label,
  subPath,
  parentPath,
  icon,
  testId,
}) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isNavFolded, foldSidebar } = useUiStorage();
  const route = useLocation();
  const { currentLocation } = useLocationStore();

  const linkBuilder = useLinkBuilder();

  const path = linkBuilder(`${parentPath ? `/${parentPath}` : ""}/${subPath}`);

  const active = subPath ? route.pathname.includes(path) : route.pathname === path;
  const childActive = parentPath ? route.pathname.includes(parentPath) : false;

  const navbarStyles = {
    [styles["navbar-link-location"]]: !!currentLocation,
    [styles["is-child"]]: !!parentPath,
    [styles["active-child"]]: active && childActive,
  };

  if (!!isParent) {
    const onNavLinkClick = () => {
      if (isNavFolded) {
        foldSidebar(!isNavFolded);
        setIsNavOpen(true);
      } else {
        setIsNavOpen(!isNavOpen);
      }
    };

    return (
      <NavLink
        className={classNames(styles["navbar-link"], navbarStyles, {
          [styles["active"]]: active || (isNavOpen && !isNavFolded),
        })}
        label={label}
        leftSection={icon}
        noWrap={true}
        childrenOffset={0}
        data-cy={testId}
        c="white"
        p="lg"
        opened={isNavOpen && !isNavFolded}
        onClick={onNavLinkClick}>
        {children}
      </NavLink>
    );
  }

  return (
    <NavLink
      className={classNames(styles["navbar-link"], navbarStyles, {
        [styles["active"]]: active,
      })}
      label={label}
      component={Link}
      to={path}
      leftSection={icon}
      noWrap={true}
      data-cy={testId}
      c="white"
      p="lg"
    />
  );
};

interface NavLink {
  isParent?: boolean;
  label: string;
  subPath: string;
  icon?: ReactNode;
  children?: NavLink[];
}

interface NavLinks {
  navLinks: NavLink[];
}

interface LocationNavLink extends NavLinks {
  permission: keyof Permissions;
  isLocation?: boolean;
}
const formatTestId = (name: string, child?: string) => {
  const base = `navigation-item-${name.toLowerCase().replace(/\s/g, "-")}`;
  if (child) return `${base}-child-${child.toLowerCase().replace(/\s/g, "-")}`;
  return base;
};

const NavBarLinks: FC<NavLinks> = ({ navLinks }) => {
  return (
    <>
      {navLinks.map((item: NavLink) => {
        if (item.isParent) {
          return (
            <ToGoNavLink {...item} key={item.label} testId={formatTestId(item.label)}>
              {item.children?.map((child: NavLink) => (
                <ToGoNavLink
                  key={child.label}
                  label={child.label}
                  subPath={child.subPath}
                  parentPath={item.subPath}
                  icon={child.icon}
                  testId={formatTestId(item.label, child.label)}
                />
              ))}
            </ToGoNavLink>
          );
        }
        return (
          <ToGoNavLink
            key={item.label}
            label={item.label}
            subPath={item.subPath}
            icon={item.icon}
            testId={formatTestId(item.label)}
          />
        );
      })}
    </>
  );
};

const NavBarLocation: FC = () => {
  const { t } = useTranslation();
  const { currentLocation: currentShop } = useLocationStore();
  const { getAllowedProperty } = useCurrentUser();
  const currentAllowedProperty = currentShop ? getAllowedProperty(currentShop.id) : undefined;
  const shopNavLinks: NavLink[] = [];
  const qrCodeLink: NavLink[] = [];

  const fetchedLocation = usePropertyQuery(currentShop?.id).data;
  if (currentAllowedProperty?.validateLegalAge) {
    qrCodeLink.push({
      label: t("menu.qrcodes"),
      subPath: "qrcodes",
      icon: <MdQrCode2 size={24} />,
    });
  }
  if (fetchedLocation) {
    updatePropertyTheme(fetchedLocation.theme ?? (DefaultTheme as Theme));
    if (fetchedLocation.shops.length === 1)
      shopNavLinks.push({
        label: t("shop.settings.title"),
        subPath: `shop/${fetchedLocation.shops?.[0]?.id}`,
        icon: <AiFillShop size={24} />,
      });
    else if (fetchedLocation.shops.length > 1)
      shopNavLinks.push({
        isParent: true,
        label: t("menu.shops.title"),
        subPath: "shop",
        icon: <AiFillShop size={24} />,
        children: fetchedLocation.shops.map((shop) => ({
          label: shop.name,
          subPath: String(shop.id),
        })),
      });
  }

  const locationNavLinks: LocationNavLink[] = [
    {
      permission: "report",
      navLinks: [
        {
          label: t("menu.administration.report"),
          subPath: "dashboard",
          icon: <HiDocumentReport size={24} />,
        },
      ],
    },
    {
      permission: "location",
      isLocation: false,
      navLinks: [
        {
          label: t("menu.locations"),
          subPath: "locations",
          icon: <AiFillShop size={24} />,
        },
      ],
    },
    {
      permission: "product",
      navLinks: [
        {
          label: t("menu.assortment"),
          subPath: "categories",
          icon: <HiOutlineCube size={24} />,
        },
      ],
    },
    {
      permission: "shop",
      isLocation: true,
      navLinks: shopNavLinks,
    },
    {
      permission: "transaction",
      navLinks: [
        {
          label: t("menu.transactions"),
          subPath: "transactions",
          icon: <HiCreditCard size={24} />,
        },
      ],
    },
    {
      permission: "qrcode",
      isLocation: true,
      navLinks: qrCodeLink,
    },
    {
      permission: "administration",
      isLocation: true,
      navLinks: [
        {
          isParent: true,
          label: t("menu.administration.title"),
          subPath: "administration",
          icon: <HiChartBar size={24} />,
          children: [
            {
              label: t("menu.administration.turnover"),
              subPath: "turnover",
            },
            {
              label: t("menu.administration.settings"),
              subPath: "ledgers",
            },
          ],
        },
      ],
    },
    {
      permission: "administration",
      isLocation: false,
      navLinks: [
        {
          label: t("menu.administration.settings"),
          subPath: "administration/ledgers",
          icon: <HiChartBar size={24} />,
        },
      ],
    },
    {
      permission: "voucher",
      isLocation: true,
      navLinks: [
        {
          isParent: true,
          label: t("menu.voucher.title"),
          subPath: "voucher",
          icon: <HiTicket size={24} />,
          children: [
            {
              label: t("menu.report"),
              subPath: "dashboard",
            },
          ],
        },
      ],
    },
    {
      permission: "digitalPriceLabels",
      isLocation: true,
      navLinks: [
        {
          label: t("menu.priceLabels"),
          subPath: "price-labels",
          icon: <BiSolidPurchaseTag size={24} />,
        },
      ],
    },
    {
      permission: "account",
      navLinks: [
        {
          label: t("menu.accounts"),
          subPath: "accounts",
          icon: <HiUserCircle size={24} />,
        },
      ],
    },
    {
      permission: "reviews",
      navLinks: [
        {
          label: t("menu.reviews"),
          subPath: "reviews",
          icon: <HiStar size={24} />,
        },
      ],
    },
  ];

  const shouldShownLinks = (item: LocationNavLink): boolean =>
    (item.isLocation && !!currentShop) ||
    (!item.isLocation && !currentShop) ||
    item.isLocation === undefined;

  return (
    <>
      {locationNavLinks.map((item: LocationNavLink) => (
        <PermissionWrapper
          key={item.permission + item.navLinks?.[0]?.label}
          permission={item.permission}
          action="read">
          {shouldShownLinks(item) && <NavBarLinks navLinks={item.navLinks} />}
        </PermissionWrapper>
      ))}
    </>
  );
};

export default Navbar;
