import api from "@/services/api";
import { DynamicDate, DynamicPricing, NotificationColors } from "@/types";
import { DynamicDateFormValues, DynamicPricingFormValues } from "@/types/formValues";
import { ActionIcon, Button, Checkbox, Group, Select, Table, Text } from "@mantine/core";
import { DateInput, TimeInput } from "@mantine/dates";
import { useForm, UseFormReturnType } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineTrash, HiPencil, HiCheck, HiX } from "react-icons/hi";

const pricePercentages = Array.from({ length: 20 }, (_, i) => ({
  value: `${i * 5}`,
  label: `${i * 5}%`,
}));

const initialDynamicDateFormValues: DynamicDateFormValues = {
  date: new Date(),
  percentage: "0",
  startTime: "00:00",
  endTime: "00:00",
};

interface DynamicPricingPanelProps {
  form: UseFormReturnType<DynamicPricingFormValues>;
  dynamicDates: DynamicDate[];
}

const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

const DynamicPricingPanel: FC<DynamicPricingPanelProps> = ({ form, dynamicDates }) => {
  const { t } = useTranslation();
  dayjs.extend(utc);

  const [dynamicDateEdit, setDynamicDateEdit] = useState<number | null>(null);
  const [createError, setCreateError] = useState<string | null>(null);
  const [updateError, setUpdateError] = useState<string | null>(null);

  const dynamicPricingMutation = useMutation(
    (form: DynamicPricingFormValues) => {
      const data: DynamicPricing = {
        dynamicPercentage: parseInt(form.dynamicPercentage),
        dynamicHoursList: form.dynamicHoursList,
      };

      return api.dynamicHours.update(data);
    },
    {
      onSuccess: () => {
        showNotification({
          color: NotificationColors.success,
          title: t("priceLabels.updatedDynamicPricing"),
          message: t("priceLabels.updatedDynamicPricingMessage"),
        });
      },
      onError: () => {
        showNotification({
          color: NotificationColors.error,
          title: t("priceLabels.updatedDynamicPricingError"),
          message: t("priceLabels.updatedDynamicPricingErrorMessage"),
        });
      },
    }
  );

  const DynamicDateCreateMutation = useMutation(
    (data: Omit<DynamicDate, "id">) => api.dynamicDate.create(data),
    {
      onSuccess: ({ data }) => {
        dynamicDates.push(data);
        showNotification({
          color: NotificationColors.success,
          title: t("priceLabels.createdDynamicDate"),
          message: t("priceLabels.createdDynamicDateMessage"),
        });
      },
      onError: () => {
        showNotification({
          color: NotificationColors.error,
          title: t("priceLabels.createdDynamicDateError"),
          message: t("priceLabels.createdDynamicDateErrorMessage"),
        });
      },
    }
  );
  const DynamicDateUpdateMutation = useMutation(
    (data: DynamicDate) => api.dynamicDate.update(data.id, data),
    {
      onSuccess: ({ data }) => {
        const index = dynamicDates.findIndex((dynamicDate) => dynamicDate.id === data.id);
        dynamicDates[index] = data;

        setDynamicDateEdit(null);

        showNotification({
          color: NotificationColors.success,
          title: t("priceLabels.updatedDynamicDate"),
          message: t("priceLabels.updatedDynamicDateMessage"),
        });
      },
      onError: () => {
        showNotification({
          color: NotificationColors.error,
          title: t("priceLabels.updatedDynamicDateError"),
          message: t("priceLabels.updatedDynamicDateErrorMessage"),
        });
      },
    }
  );

  const DynamicDateDeleteMutation = useMutation((id: number) => api.dynamicDate.delete(id), {
    onSuccess: () => {
      const index = dynamicDates.findIndex(
        (dynamicDate) => dynamicDate.id === DynamicDateDeleteMutation.variables
      );
      dynamicDates.splice(index, 1);

      showNotification({
        color: NotificationColors.success,
        title: t("priceLabels.deletedDynamicDate"),
        message: t("priceLabels.deletedDynamicDateMessage"),
      });
    },
    onError: () => {
      showNotification({
        color: NotificationColors.error,
        title: t("priceLabels.deletedDynamicDateError"),
        message: t("priceLabels.deletedDynamicDateErrorMessage"),
      });
    },
  });

  const createForm: UseFormReturnType<DynamicDateFormValues> = useForm({
    initialValues: initialDynamicDateFormValues,
  });

  const editForm: UseFormReturnType<DynamicDateFormValues> = useForm({
    initialValues: initialDynamicDateFormValues,
  });

  const getDayjsDate = (date: Date, time: string) => {
    const [hours, minutes] = time.split(":");
    const dateObj = dayjs
      .utc(date)
      .set("hour", parseInt(hours))
      .set("minute", parseInt(minutes))
      .set("second", 0)
      .set("millisecond", 0);
    return dateObj;
  };

  const getEndDayjsDate = (date: Date, time: string, startTime: string) => {
    if (time <= startTime) {
      return getDayjsDate(date, time).add(1, "day");
    } else {
      return getDayjsDate(date, time);
    }
  };

  const isOverlapping = (dynamicDate: DynamicDateFormValues, dynamicDates: DynamicDate[] = []) => {
    const startAt = getDayjsDate(dynamicDate.date, dynamicDate.startTime);
    const endAt = getEndDayjsDate(dynamicDate.date, dynamicDate.endTime, dynamicDate.startTime);

    return dynamicDates.some((dynamicDate: DynamicDate) => {
      const dynamicDateStartAt = dayjs.utc(dynamicDate.startAt);
      const dynamicDateEndAt = dayjs.utc(dynamicDate.endAt);

      return (
        (dynamicDateEndAt.isAfter(startAt) && dynamicDateStartAt.isBefore(endAt)) ||
        dynamicDateEndAt.isSame(startAt) ||
        dynamicDateStartAt.isSame(endAt)
      );
    });
  };

  const onCreateClick = (dynamicDate: DynamicDateFormValues) => {
    if (isOverlapping(dynamicDate, dynamicDates)) {
      setCreateError(t("form.invalidTimeRange"));
      return;
    } else if (!!createError) {
      setCreateError(null);
    }

    const data = {
      percentage: parseInt(dynamicDate.percentage),
      startAt: getDayjsDate(dynamicDate.date, dynamicDate.startTime).toISOString(),
      endAt: getEndDayjsDate(
        dynamicDate.date,
        dynamicDate.endTime,
        dynamicDate.startTime
      ).toISOString(),
    };

    DynamicDateCreateMutation.mutate(data);
  };

  const onEditClick = (dynamicDateId: number) => {
    const dynamicDate = dynamicDates.find((dynamicDate) => dynamicDate.id === dynamicDateId);

    if (!dynamicDate) {
      return;
    }

    editForm.setValues({
      date: new Date(dynamicDate.startAt),
      percentage: String(dynamicDate.percentage),
      startTime: dayjs.utc(dynamicDate.startAt).format("HH:mm"),
      endTime: dayjs.utc(dynamicDate.endAt).format("HH:mm"),
    });
    setDynamicDateEdit(dynamicDateId);
  };

  const onUpdateClick = (dynamicDateId: number) => {
    const dynamicDate = dynamicDates.find((dynamicDate) => dynamicDate.id === dynamicDateId);

    if (!dynamicDate) {
      return;
    }

    if (
      isOverlapping(
        editForm.values,
        dynamicDates.filter((e) => e.id !== dynamicDateId)
      )
    ) {
      setUpdateError(t("form.invalidTimeRange"));
      return;
    } else if (!!updateError) {
      setUpdateError(null);
    }
    DynamicDateUpdateMutation.mutate({
      id: dynamicDate.id,
      percentage: parseInt(editForm.values.percentage),
      startAt: getDayjsDate(editForm.values.date, editForm.values.startTime).toISOString(),
      endAt: getEndDayjsDate(
        editForm.values.date,
        editForm.values.endTime,
        editForm.values.startTime
      ).toISOString(),
    });
  };

  const rows = dynamicDates.map((dynamicDate) =>
    dynamicDateEdit === dynamicDate.id ? (
      <Table.Tr key={dynamicDate.id} data-cy={`dynamic-date-editing-${dynamicDate.id}`}>
        <Table.Td>
          <DateInput
            minDate={new Date()}
            valueFormat={`[${dayjs(editForm.values.date).format("dddd D MMMM YYYY")}]`}
            style={{ width: "100%" }}
            data-cy={`dynamic-date-edit-date-${dynamicDate.id}`}
            {...editForm.getInputProps("date")}
          />
        </Table.Td>
        <Table.Td>
          <Select
            data={pricePercentages}
            allowDeselect={false}
            style={{ width: "100px" }}
            data-cy={`dynamic-date-edit-percentage-${dynamicDate.id}`}
            {...editForm.getInputProps("percentage")}
          />
        </Table.Td>
        <Table.Td>
          <TimeInput
            style={{ width: "fit-content" }}
            data-cy={`dynamic-date-edit-start-time-${dynamicDate.id}`}
            {...editForm.getInputProps("startTime")}
          />
        </Table.Td>
        <Table.Td>
          <Group gap={4}>
            <TimeInput
              style={{ width: "fit-content" }}
              data-cy={`dynamic-date-edit-end-time-${dynamicDate.id}`}
              {...editForm.getInputProps("endTime")}
            />
            <Text size="sm" pt={1} ml={3} data-cy={`dynamic-date-edit-next-day-${dynamicDate.id}`}>
              {editForm.values.startTime >= editForm.values.endTime &&
                `(${t("on")} ${t(
                  daysOfWeek[new Date(editForm.values.date).getDay() % 7]
                ).toLowerCase()})`}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td>
          <Group gap={0}>
            <ActionIcon
              variant="transparent"
              c="black"
              onClick={() => onUpdateClick(dynamicDate.id)}
              data-cy={`dynamic-date-update-${dynamicDate.id}`}>
              <HiCheck />
            </ActionIcon>
            <ActionIcon
              variant="transparent"
              c="black"
              onClick={() => setDynamicDateEdit(null)}
              data-cy={`dynamic-date-cancel-${dynamicDate.id}`}>
              <HiX />
            </ActionIcon>
            {updateError && (
              <Text c="red" ml="" size="xs" data-cy={`dynamic-date-edit-error-${dynamicDate.id}`}>
                {updateError}
              </Text>
            )}
          </Group>
        </Table.Td>
      </Table.Tr>
    ) : (
      <Table.Tr
        key={dynamicDate.id}
        style={{ height: "51px" }}
        data-cy={`dynamic-date-${dynamicDate.id}`}>
        <Table.Td pl={23} pt={8}>
          <Text size="sm">{dayjs.utc(dynamicDate.startAt).format("dddd D MMMM YYYY")}</Text>
        </Table.Td>
        <Table.Td pl={23} pt={8}>
          <Text size="sm">{dynamicDate.percentage}%</Text>
        </Table.Td>
        <Table.Td pl={23} pt={8}>
          <Text size="sm">{dayjs.utc(dynamicDate.startAt).format("HH:mm")}</Text>
        </Table.Td>
        <Table.Td pl={23} pt={8}>
          <Group gap={25}>
            <Text size="sm">{dayjs.utc(dynamicDate.endAt).format("HH:mm")}</Text>
            <Text size="sm" data-cy={`dynamic-date-next-day-${dynamicDate.id}`}>
              {dayjs.utc(dynamicDate.startAt).day() !== dayjs.utc(dynamicDate.endAt).day() &&
                `(${t("on")} ${t(daysOfWeek[new Date(dynamicDate.startAt).getDay() % 7]).toLowerCase()})`}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td>
          <Group gap={0}>
            <ActionIcon
              variant="transparent"
              c="black"
              onClick={() => onEditClick(dynamicDate.id)}
              data-cy={`dynamic-date-edit-${dynamicDate.id}`}>
              <HiPencil />
            </ActionIcon>
            <ActionIcon
              variant="transparent"
              c="black"
              onClick={() => DynamicDateDeleteMutation.mutate(dynamicDate.id)}
              data-cy={`dynamic-date-delete-${dynamicDate.id}`}>
              <HiOutlineTrash />
            </ActionIcon>
          </Group>
        </Table.Td>
      </Table.Tr>
    )
  );

  return (
    <>
      <form onSubmit={form.onSubmit((values) => dynamicPricingMutation.mutate(values))}>
        <Group>
          <Text fw={700}>{t("priceLabels.priceIncrease")}:</Text>
          <Select
            data={pricePercentages}
            defaultValue="0"
            allowDeselect={false}
            style={{ width: "100px" }}
            data-cy="dynamic-pricing-select"
            {...form.getInputProps("dynamicPercentage")}
          />
        </Group>
        <Text c="grey.3" size="xs" mt="xs" mb="xl">
          {t("priceLabels.priceIncreaseDescription")}
        </Text>
        {form.values.dynamicHoursList.map((value, index) => {
          const dayOfWeekIndex: number = value.dayOfWeek - 1;
          return (
            <Group key={daysOfWeek[dayOfWeekIndex]} mt="sm">
              <Checkbox
                defaultValue={false}
                data-cy={`dynamic-pricing-checkbox-${index}`}
                {...form.getInputProps(`dynamicHoursList.${index}.isEnabled`, {
                  type: "checkbox",
                })}
              />
              <Text fw={700} style={{ width: "125px" }}>
                {t(daysOfWeek[dayOfWeekIndex])}
              </Text>
              <TimeInput
                defaultValue="00:00"
                disabled={!value.isEnabled}
                data-cy={`dynamic-pricing-start-time-${index}`}
                {...form.getInputProps(`dynamicHoursList.${index}.startTime`)}
              />
              <Text>{t("until")}</Text>
              <TimeInput
                defaultValue="00:00"
                disabled={!value.isEnabled}
                data-cy={`dynamic-pricing-end-time-${index}`}
                {...form.getInputProps(`dynamicHoursList.${index}.endTime`)}
              />
              {value.startTime >= value.endTime && value.isEnabled && (
                <Text data-cy={`dynamic-pricing-next-day-${index}`}>
                  {`(${t("on")} ${t(daysOfWeek[(dayOfWeekIndex + 1) % 7]).toLowerCase()})`}
                </Text>
              )}
            </Group>
          );
        })}
        <Button mt="xs" size="md" color="cta.4" type="submit" data-cy="dynamic-pricing-save">
          {t("save")}
        </Button>
        <Text c="red" mt="sm" size="xs" data-cy="dynamic-pricing-error">
          {form.errors["dynamicHoursList"]}
        </Text>
      </form>
      <Text size="lg" mt="xl" fw={700}>
        {t("priceLabels.exceptions")}
      </Text>
      <Text size="sm">{t("priceLabels.exceptionsDescription")}</Text>
      <Table style={{ width: "fit-content" }}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{ width: "300px" }} pl={23}>
              {t("when")}
            </Table.Th>
            <Table.Th style={{ width: "125px" }} pl={23}>
              {t("priceLabels.priceIncrease")}
            </Table.Th>
            <Table.Th style={{ width: "100px" }} pl={23}>
              {t("dateTime.start")}
            </Table.Th>
            <Table.Th style={{ width: "225px" }} pl={23}>
              {t("dateTime.end")}
            </Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      <Text size="md" mt="lg" mb="sm" fw={700}>
        {`${t("priceLabels.addException")}:`}
      </Text>
      <form onSubmit={createForm.onSubmit((values) => onCreateClick(values))}>
        <Group gap={0} ml={10}>
          <DateInput
            minDate={new Date()}
            valueFormat={`[${dayjs(createForm.values.date).format("dddd D MMMM YYYY")}]`}
            mr="lg"
            style={{ width: "280px" }}
            data-cy="dynamic-date-date"
            {...createForm.getInputProps("date")}
          />
          <Select
            data={pricePercentages}
            allowDeselect={false}
            style={{ width: "100px" }}
            mr={45}
            data-cy="dynamic-date-percentage"
            {...createForm.getInputProps("percentage")}
          />
          <TimeInput
            style={{ width: "fit-content" }}
            data-cy="dynamic-date-start-time"
            {...createForm.getInputProps("startTime")}
          />
          <Text mx={4}>{t("until")}</Text>
          <TimeInput
            style={{ width: "fit-content" }}
            data-cy="dynamic-date-end-time"
            {...createForm.getInputProps("endTime")}
          />
          <Text size="sm" ml={8} style={{ width: "125px" }} data-cy="dynamic-date-next-day">
            {createForm.values.startTime >= createForm.values.endTime &&
              `(${t("on")} ${t(daysOfWeek[createForm.values.date.getDay() % 7]).toLowerCase()})`}
          </Text>
          <Button size="sm" color="cta.4" ml={10} data-cy="dynamic-date-add" type="submit">
            {t("save")}
          </Button>
          {createError && (
            <Text c="red" ml="xs" size="xs" data-cy="dynamic-date-error">
              {createError}
            </Text>
          )}
        </Group>
      </form>
    </>
  );
};

export default DynamicPricingPanel;
