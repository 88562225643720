import { DateRangeOption, ReceiptState, VoucherType } from "types";

export default {
  fold: "Menu inklappen",
  version: "Versie",
  filter: "Filters aanpassen",
  submit: "Bevestigen",
  save: "Opslaan",
  cancel: "Annuleren",
  back: "Terug",
  archive: "Archiveren",
  restore: "Herstellen",
  lookup: "Bekijken",
  edit: "Bewerken",
  disabled: "inactief",
  publish: "Publiceren",
  delete: "Verwijderen",
  download: "Download",
  saveConcept: "Concept opslaan",
  concept: "Concept",
  import: "Importeren",
  user: "Gebruiker",
  search: "Zoeken",
  date: "Datum",
  all: "Alle",
  export: "Exporteer",
  backToOverview: "Terug naar het overzicht",
  backToAdminSpace: "Terug naar Beheer",
  adminSpace: "Beheer",
  selectDate: "Selecteer datum",
  selectTime: "Selecteer tijd",
  manager: "Manager",
  admin: "Admin",
  addProduct: "Product toevoegen",
  settings: "Instellingen",
  cashRegisters: "Kassa's",
  required: "Dit veld is verplicht om in te vullen",
  drinkingAge: "18+",
  name: "Naam",
  locationOrShop: "Locatie/shop",
  errorLoadingContent: "Kon inhoud niet laden",
  nothingFound: "Geen resultaten gevonden",
  tryAgain: "Er is iets fout gegaan, probeer het opnieuw",
  unknownError: "Er is een onbekende fout opgetreden",
  priceAdvice: "Adviesprijs",
  shopPrice: "Shopprijs",
  nonShops: "Wordt niet aangeboden",
  productsAmount: "Aantal producten",
  day: "dag",
  week: "week",
  month: "maand",
  yes: "Ja",
  no: "Nee",
  apply: "Toepassen",
  monday: "Maandag",
  tuesday: "Dinsdag",
  wednesday: "Woensdag",
  thursday: "Donderdag",
  friday: "Vrijdag",
  saturday: "Zaterdag",
  sunday: "Zondag",
  until: "tot",
  on: "op",
  when: "Wanneer",

  account: {
    lastSeen: "Laatst actief",
    addNewAccount: "Nieuw account toevoegen",
    overviewAccount: "Account overzicht",
    add: "Account toevoegen",
    edit: "Account wijzigen",
    deleteAccount: "Account verwijderen",
    properties: "Toegang tot locaties",
    propertyAccess: "{{value}} hotels",
    accounts: "Accounts",
    confirmRemove:
      "Weet u zeker dat u dit account wilt verwijderen? Dit kan niet ongedaan worden gemaakt.",
    updatedMessage: "Account is aangepast",
    settings: {
      email: {
        title: "E-mailadres",
        placeholder: "Bijv. voorbeeld@hotelnaam.nl",
      },
    },
    appToken: {
      errorTitle: "Fout bij het ophalen van de login code",
      errorMessage: "Probeer het later opnieuw",
      modalTitle: "QR code",
      modalText: "Scan deze QR code met de Anna-Bel app",
      button: "Genereer app login",
    },
  },
  productsCount_one: "{{count}} product",
  productsCount_other: "{{count}} producten",
  latestSucceededTransaction: "Laatste succesvolle transactie",
  product: {
    settings: {
      translations: {
        nl: {
          name: {
            title: "Productnaam",
            placeholder: "Bijv. 'Koffie'",
          },
          tags: {
            title: "Tags",
            placeholder: "Bijv. 'Koffie, drinken'",
            description:
              "Zoektermen waarop het product op de kassa gevonden kan worden, gescheiden door een komma",
          },
        },
        en: {
          name: {
            title: "Engelse productnaam",
            placeholder: "Bijv. 'Coffee'",
            description: "Optioneel",
          },
          tags: {
            title: "Engelse tags",
            placeholder: "Bijv. 'Coffee, Drinks'",
            description:
              "Zoektermen waarop het product op de kassa gevonden kan worden, gescheiden door een komma",
          },
        },
        de: {
          name: {
            title: "Duitse productnaam",
            placeholder: "Bijv. 'Koffie'",
            description: "Optioneel",
          },
          tags: {
            title: "Duitse tags",
            placeholder: "Bijv. 'Kaffee, Getränke'",
            description:
              "Zoektermen waarop het product op de kassa gevonden kan worden, gescheiden door een komma",
          },
        },
      },
      imageCdnUrl: {
        placeholder: "https://cdn.bfldr.com/image",
      },
      price: {
        title: "Adviesprijs",
        placeholder: "1,00",
        description: "Inclusief BTW",
      },
      ean: {
        title: "EAN-code",
        placeholder: "Bijv. '12345678'",
        description: "Indien aanwezig",
      },
      locationConfig: {
        price: {
          title: "Shopprijs",
          placeholder: "1,00",
          alternativeDescription:
            "Wanneer dit niet wordt ingesteld, wordt de adviesprijs aangehouden",
        },
      },
      alternativeCategory: {
        title: "2e categorie",
        description: "Optioneel",
      },
    },
    exportAll: "Alle producten {{hotel}}",
    new: "Product aanmaken",
    lookup: "Bekijk producten",
    locationAdded: "Eigen product",
    product: "Product",
    products: "Producten",
    all: "Alle producten",
    archive: "Product archiveren",
    restore: "Product herstellen",
    vat: "BTW type",
    image: "Productfoto",
    visibility: "Vindbaarheid",
    translations: "Vertalingen",
    vouchers: "Vouchers",
    category: "Categorie",
    comaSeparated: "Gescheiden door een komma",
    welcomeDrink: "Welkomstdrankje",
    interchangeable: "Inwisselbaar",
    ageRestriction: "Leeftijdsrestrictie",
    ageRestrictionText: "18 jaar en ouder",
    shops: "Actieve shops",
    deposit: "Statiegeld",
    ledgerAccount: "Grootboekrekening",
    abbreviationLedgerAccount: "Gr.boekrek.",
    viewProducts: "Producten bekijken",
    productUpdatedMessage: "Product is aangepast",

    // Placeholders
    depositPlaceholder: "Geen statiegeld",
    categoryPlaceholder: "Selecteer een categorie",
    shopsPlaceholder: "Selecteer een shop",
    ledgerAccountPlaceholder: "Selecteer een grootboekrekening",

    // Notifications
    productCreatedTitle: "Product is toegevoegd",
    productUpdatedTitle: "Product is gewijzigd",
    productDeletedTitle: "Product is verwijderd",
    productArchivedTitle: "Product is gearchiveerd",
    productRestoredTitle: "Product is hersteld",
    productCreated: "is aangemaakt",
    productUpdated: "is gewijzigd",
    productDeleted: "is verwijderd",
    productArchived: "is gearchiveerd",
    productRestored: "is hersteld",
    deleteTitle: "Product verwijderen?",
    archiveTitle: "Product archiveren?",
    restoreTitle: "Product herstellen?",
    deleteWarning: "Weet je zeker dat je dit product wilt verwijderen?",
    archiveWarning:
      "Weet je zeker dat je dit product wilt archiveren? Het product zal niet meer zichtbaar zijn in de shops.",
    restoreWarning:
      "Weet je zeker dat je dit product wilt herstellen? Het product is weer zichtbaar in de shops.",
    archiveDate: "Archiveer datum",
    noCategorySelected: "Geen categorie geselecteerd",
    archivedProducts: "Gearchiveerde producten",
    addedProducts: "Toegevoegde producten",
    globalProducts: "Globale producten",
    archivedProductsDescription: "Gearchiveerde producten zijn niet zichtbaar in de shops",
    noArchiveDate: "Geen archiveringsdatum",
  },
  combiDeal: {
    new: "Combi-deal aanmaken",
    title: "Combi-deals",
    name: "Combi-deal naam",
    availability: "Beschikbaar in hotels",
    sold: "Verkocht",
    price: "Prijs",
    status: "Status",
    create: "Combi-deal toevoegen",
    form: {
      invalid: "Niet alle velden zijn correct ingevuld",
      productGroup: {
        title_zero: "Eerste productgroep van de combi-deal",
        title_one: "Tweede productgroep van de combi-deal",
        title_other: "productgroep {{count}} van de combi-deal",
        placeholder: "Zoek op product",
      },
      price: {
        title: "Shop prijs",
        description: "Inclusief statiegeld",
        placeholder: "7,50",
      },
      availability: {
        title: "Hotel beschikbaarheid",
        placeholder: "Zoek op hotel",
      },
      translations: {
        nl: {
          title: "Combi-deal naam",
          placeholder: "Drankje + chips",
        },
        en: {
          title: "Engelse vertaling",
          placeholder: "Drink + snack",
        },
        de: {
          title: "Duitse vertaling",
          placeholder: "Getränk mit Imbiss",
        },
      },
    },
    delete: {
      title: "Combi-deal verwijderen?",
      confirm: "Weet je zeker dat je deze combi-deal wilt verwijderen?",
    },
    message: {
      success: {
        title: "Combi-deal aanpassing is verwerkt",
        create: "Combi-deal {{value}} is aangemaakt",
        update: "Combi-deal {{value}} is aangepast",
        delete: "Combi-deal is verwijderd",
      },
      error: {
        delete: "Combi-deal kon niet worden verwijderd",
      },
    },
  },
  auth: {
    email: {
      title: "E-mailadres",
      placeholder: "Bijv. voorbeeld@hotelnaam.nl",
    },
    password: {
      title: "Wachtwoord",
      placeholder: "********",
    },
    login: "Inloggen",
    logout: "Uitloggen",
    credentialsErrorTitle: "Oh nee!",
    credentialsError: "E-mailadres of wachtwoord onjuist.",
    logoutErrorTitle: "Uitloggen mislukt",
    logoutErrorMessage: "Er is iets fout gegaan tijdens het uitloggen, probeer het opnieuw.",
    noGroup: "Gebruiker is niet toegewezen aan een groep",
    goToLogin: "Ga naar inloggen",
  },
  form: {
    // account
    invalidEmail: "Geen geldig e-mailadres",
    username: "Gebruikersnaam",
    mailWillBeSent:
      "Bij het aanmaken van een nieuw account wordt er een mail verstuurd naar het ingevuld e-mailadres.",
    // password
    password: "Wachtwoord",
    setPassword: "Nieuw wachtwoord",
    setPasswordConfirm: "Bevestig wachtwoord",
    savePassword: "Wachtwoord opslaan",
    forgotPassword: "Wachtwoord vergeten",
    passwordMinimum: "Wachtwoord (minstens 12 tekens)",
    invalidPassword: "Vul minimaal 12 tekens in",
    emptyPassword: "Vul hier uw wachtwoord in.",
    role: "Rol",
    passwordToShort: "Het wachtwoord moet minimaal 12 tekens bevatten",
    passwordsDontMatch: "Wachtwoorden komen niet overeen",
    passwordSetSuccess: "Wachtwoord is succesvol ingesteld",
    // product
    invalidName: "Gegeven naam is te kort",
    invalidPrice: "Geen geldige prijs",
    invalidEan: "Geen geldige EAN-code",
    duplicateEan: "EAN-code bestaat al",
    invalidLabel: "Geen geldig prijskaartje",
    invalidPhoto: "Geen foto geselecteerd",
    invalidLedgerAccount: "Geen grootboekrekening geselecteerd",
    invalidCategory: "Geen categorie geselecteerd",
    invalidAlternativeCategory: "tweede categorie is hetzelfde",
    invalidAllowedProperties: "Geen locaties geselecteerd",
    // Error
    notAllFieldsCorrectlyFilled: "Niet alle vereiste velden zijn correct ingevuld!",
    // forgot
    emailFillIn:
      "Vul je e-mailadres in en we sturen je instructies om een nieuw wachtwoord in te stellen.",
    resetEmail: "Verstuur instructies",
    forgotEmail: "Wachtwoord vergeten?",
    youGotMailTitle: "Je hebt mail",
    youGotMailMessage: "Met een link voor een nieuw wachtwoord",
    setPasswordTitle: "Stel een nieuw wachtwoord in",
    setPasswordWriteHere: "Vul hieronder uw gewenste nieuwe wachtwoord in:",
    invalidToken: "De link is niet meer geldig, verstuur een nieuwe link vanaf de inlogpagina.",
    invalidCdnImage: "De URL moet een Brandfolder URL zijn",
    // Dynamic pricing
    invalidTimeRange: "Tijden per weekdag mogen niet overlappen.",
  },
  image: {
    image: "Afbeelding",
    imageSearch: "Upload afbeelding",
    invalidType: "Geen geldig bestandstype",
    tooLarge: "Bestand is te groot (Max. 5MB)",
    tooManyFiles: "Er mag maximaal 1 afbeelding geüpload worden",
  },
  language: {
    de: "Duits",
    en: "Engels",
    nl: "Nederlands",
  },
  country: {
    de: "Duitsland",
    nl: "Nederland",
  },
  menu: {
    accounts: "Accounts",
    administration: {
      title: "Administratie",
      turnover: "Omzet",
      settings: "Instellingen",
      ledgerAccount: "Grootboekrekening",
      report: "Rapportage",
    },
    dashboard: "Dashboard",
    report: "Rapportage",
    shops: {
      title: "Shop instellingen",
      create: "Shop aanmaken",
    },
    locations: "Locaties",
    transactions: "Transacties",
    qrcodes: "QR-codes",
    logout: "Uitloggen",
    products: "Producten",
    assortment: "Assortiment",
    reviews: "Beoordelingen",
    voucher: {
      title: "Vouchers",
    },
    priceLabels: "Digitale prijskaartjes",
  },
  category: {
    settings: {
      translations: {
        nl: {
          title: "Categorie naam",
          placeholder: "Koek en snoep",
        },
        en: {
          title: "Engelse benaming",
          placeholder: "Cookies and sweets",
        },
        de: {
          title: "Duitse benaming",
          placeholder: "Kekse und Süßigkeiten",
        },
      },
    },
    add: "Categorie toevoegen",
    addedCategories: "Toegevoegde categorieën",
    categoryUpdatedMessage: "Categorie {{value}} is succesvol aangepast",
    categoryAddedMessage: "Categorie {{value}} is succesvol aangemaakt",
    category: "categorie",
    categories: "Categorieën",
    assortment: "Assortiment",
    prevCategory: "Vorige categorie",
    edit: "Categorie wijzigen",
    new: "Nieuwe categorie",
    addNew: "Nieuwe categorie toevoegen",
    name: "Categorie naam",
    photo: "Categorie foto",
    deleteTitle: "Categorie verwijderen?",
    deleted: "Categorie verwijderd",
    deleteError: "Categorie kon niet worden verwijderd",
    invalidName: "Categorie naam moet minimaal 3 karakters bevatten",
    confirmRemoveCategory:
      "Weet u zeker dat u deze categorie wilt verwijderen? Dit kan niet ongedaan worden gemaakt.",
  },
  translations: "Vertalingen",
  shop: {
    settings: {
      title: "Shop instellingen",
      name: { title: "Shopnaam", placeholder: "Naam van de shop" },
      pickup: { title: "Later ophalen van artikelen", label: "Ophalen activeren" },
      replyToEmail: {
        title: "Bevestigings e-mailadres (naar de gast met bon)",
        placeholder: "voorbeeld@hotelnaam.nl",
      },
      orderingEmails: {
        title: "E-mailadres, komma gescheiden (voor op te halen bestellingen)",
        placeholder: "voorbeeld@email.nl, voorbeeld2@email.nl",
      },
      receiptName: {
        title: "Shopnaam op bon",
        placeholder: "Bijv. Valk Exclusief - Utrecht",
      },
      address: { title: "Adres", placeholder: "Bijv. Waterstraat 1" },
      postalCode: { title: "Postcode", placeholder: "Bijv. 1234VD" },
      city: { title: "Plaats", placeholder: "Bijv. Utrecht" },
      country: {
        title: "Standaard taal",
        placeholder: "Bijv. Nederland",
        description:
          "De volgende instellingen zullen zich aanpassen op basis van het gekozen land: BTW tarief, statiegeld en alcohol controle.",
      },
      vatId: { title: "BTW-nummer", placeholder: "Bijv. NL123456789B01" },
      chamberOfCommerceId: {
        title: "KvK-nummer",
        placeholder: "Bijv. 12345678",
      },
      ip: { title: "IP-adres", placeholder: "Bijv. 192.123.1.12" },
      port: { title: "Poort", placeholder: "Bijv. 8080" },
      configPort: { title: "Config poort", placeholder: "Bijv. 8443" },
    },
    errors: {
      invalidName: "Shopnaam moet minimaal 3 karakters lang zijn",
      invalidIp: "Geen geldig IP-adres",
      invalidPort: "Geen geldige poort",
      invalidShortcut: "Geen snelkoppeling gekozen",
      invalidTheme: "Geen geldige kleurcode",
      invalidVatId: "Geen geldig BTW-nummer",
      invalidAddress: "Geen geldig adres, vul een adresnummer in",
      invalidPostalCode: "Geen geldige postcode",
      invalidCity: "Geen geldige plaatsnaam",
      invalidEmail: "Geen geldig e-mailadres",
      invalidEmails: "Geen geldig e-mailadres(sen)",
    },
    shop: "shop",
    allShops: "Alle shops",
    addShop: "Shop aanmaken",
    confirmRemoveShop:
      "Weet u zeker dat u deze shop wilt verwijderen? Dit kan niet ongedaan worden gemaakt.",
    confirmRemoveLocation:
      "Weet u zeker dat u deze locatie wilt verwijderen. Dit kan niet ongedaan worden gemaakt.",
    shopAdded: "Shop is aangemaakt",
    shopAddedMessage: "Shop {{value}} is succesvol aangemaakt",
    shopUpdatedMessage: "Shop {{value}} is succesvol bijgewerkt",
    deleteTitle: "Shop verwijderen?",
    shopDeleted: "Shop is verwijderd",
    headings: {
      main: { title: "Shop instellingen" },
      priceLabels: { title: "Digitale prijskaart instellingen" },
      shortcuts: { title: "Snelkoppelingen" },
      categoryOrder: { title: "Volgorde categorieën" },
    },
    url: "Shop URL",
    shortcut: {
      head: "Snelkoppeling {{value}}",
      one: "1",
      two: "2",
      three: "3",
      four: "4",
    },

    shortcutPlaceholder: "Zoek op product",
  },
  cashRegister: {
    card: {
      extTerminalId: { title: "Terminal ID", placeholder: "Adyen Terminal ID" },
      merchant: { title: "Adyen Merchant", placeholder: "Adyen Merchant" },
      model: {
        type: "Type kassa",
        table: "Tafelmodel",
        floor: "Staande model",
      },
    },
    cashRegister: "Kassa",
    cashRegisters: "Kassa's",
    add: "Kassa toevoegen",
    deleteTitle: "Kassa verwijderen?",
    deleted: "Kassa is verwijderd",
    deletedMessage: "Kassa {{value}} is succesvol verwijderd",
    updated: "Kassa is aangepast",
    confirmRemove:
      "Weet u zeker dat u deze kassa wilt verwijderen? Dit kan niet ongedaan worden gemaakt.",
  },
  location: {
    title: "Locatie",
    settings: {
      code: { title: "Locatie code", placeholder: "UTR" },
      name: { title: "Locatie naam", placeholder: "Utrecht" },
    },
  },
  visibility: {
    title: "Categorie inactief stellen",
  },
  qrcode: {
    helper:
      "Genereer de QR-codes door op de knop 'exporteer .pdf' te klikken. Ze worden dan meteen gedownload als PDF en kunnen vervolgens geprint worden.",
    export: {
      success: {
        title: "QR-codes succesvol gedownload",
      },
      error: {
        title: "Fout bij exporteren",
        message:
          "Kon geen QR-codes exporteren. Neem contact op met Valk Digital voor verdere ondersteuning.",
      },
    },
  },
  transactions: {
    transaction: "Transactie",
    transactions: "Transacties",
    id: "Bon ID",
    amount: "Totaalbedrag",
    amountProduct_one: "Totaalbedrag",
    amountProduct_zero: "Totaalbedrag",
    amountProduct_other: "Totaalbedrag ({{count}})",
    deposit: "Statiegeld",
    turnover: "Omzet",
    paid: "Betaald",
    method: "Betaalmethode",
    location: "Shop",
    time: "Tijd",
    receipt: "Bon",
    sentReceipts: "Verstuurde bonnen",
    email: "E-mailadres",
    sentAt: "Verstuurd op",
    state: "Status",
    cardnumber: "Kaart informatie",
    methods: "Betaalmethoden",
    count: "Aantal",
    product: "Product",
    price: "Prijs p.s.",
    total: "Totaal",
    subTotal: "Subtotaal",
    purchaseList: "Afrekening",
    legalAge: "18+ Antwoord",
    legalAgeWithCheck: "18+ QR-check",
    account: "Valk Account",
    pickup: "Ophalen",
    name: "Naam",
    phone: "Telefoon",
    shop: "Shop",
    rating: "Beoordeling",
    message: "Opmerking",
    dayTime: "Datum & Tijd",
    adyenId: "Adyen-ID",
    printReceipt: "Voer een e-mailadres in om de bon te versturen",
    printReceiptBlocked:
      "Het is niet mogelijk de bon te versturen omdat de betaling niet afgerond is.",
    bonMethod: "Bon Keuze",
    filterOnTime: "Zoek op datum",
    states: {
      [ReceiptState.CREATED]: "Gestart",
      [ReceiptState.PROCESSING]: "In behandeling",
      [ReceiptState.SUCCESS]: "Afgerond",
      [ReceiptState.FAILED]: "Afgebroken",
      all: "Alle",
    },
    loading: "Bon laden...",
    errorNameLoading: "Kon productnaam niet vinden",
  },
  ledgers: {
    settings: {
      code: { title: "Grootboekrekening", placeholder: "12345" },
      description: {
        title: "Omschrijving",
        placeholder: "Grootboekrekening",
      },
      extVatCode: {
        title: "BTW code",
        placeholder: "10001",
      },
      ledgerAccountExtId: {
        title: "Grootboekrekening",
      },
      method: { title: "Betalingswijze" },
      deposit: "Statiegeld",
    },
  },
  administration: {
    administration: "Administratie",
    ledger: "Grootboek",
    duplicateDeposit:
      "Er kan maar één grootboekrekening van het type 'statiegeld' ingesteld worden per locatie.",
    defaultLedgers: "Standaard grootboekrekeningen",
    otherLedgers: "Eigen grootboekrekeningen",

    // turnover
    turnoverGroup: "Omzetgroep",
    turnover: "Omzet",
    ledgerNumber: "Grootboekrek.",
    description: "Omschrijving",
    exclusive: "Excl.",
    inclusive: "Bedrag",
    vat: "BTW",
    total: "Totaal",
    paymentMethod: "Betalingswijze",
    paymentMethodError: "Kon niet elke betalingswijze opslaan",

    // ledger
    ledgerAccounts: "Grootboekrekeningen",
    category: "Categorie",
    otherProducts: "Overige producten",
    code: "Grootboek code",
    amount: "Aantal producten",
    add: "Grootboekrekening toevoegen",
    new: "Nieuwe grootboekrekening",
    errorFetchingPaymentMethods: "Fout bij het ophalen van de betaalmethodes",

    // payment Methods
    payment: "Grootboekrekeningen voor betaalwijzen",
    addPayment: "Betaalwijze toevoegen",

    // notification
    deleteTitle: "Grootboekrekening verwijderen",
    confirmRemove:
      "Weet u zeker dat u deze grootboekrekening wilt verwijderen? Dit kan niet ongedaan worden gemaakt.",

    updated: "Grootboekrekening '{{value}}' is aangepast",
    deleted: "Grootboekrekening {{value}} is succesvol verwijderd",
    deletedFailed: "Er zijn producten gekoppeld aan {{value}}, verwijderen is niet mogelijk.",
    created: "Grootboekrekening '{{value}}' is succesvol aangemaakt",

    saved: "Grootboekrekening wijzigingen opgeslagen",
  },
  locations: {
    title: "Locaties",
    location: "Locatie",
    shop: "Shops",
    locationCode: "Locatie code",
    inActiveLocation: "In-active locaties",
    inActiveLocationDescription:
      "Bij deze shops zijn er de afgelopen {{time}}uur geen transacties gedaan.",
    newLocation: "Locatie toevoegen",
    settings: {
      name: { title: "Locatienaam", placeholder: "Hotel Utrecht" },
      extId: { title: "Locatie code", placeholder: "UTR" },
      extVatId: { title: "BTW nummer", placeholder: "NL123456789B01" },
      extChamberOfCommerceId: { title: "KvK nummer", placeholder: "12345678" },
      address: { title: "Adres", placeholder: "Winthontlaan 4" },
      postalCode: { title: "Postcode", placeholder: "1234 VD" },
      city: { title: "Plaats", placeholder: "Utrecht" },
      country: { title: "Land", placeholder: "Nederland" },
      vouchers: {
        [VoucherType.Welcome]: "Welkomstvouchers",
        [VoucherType.Employee]: "Personeelskorting",
      },
      legalAge: {
        title: "Vragen om legitimatie",
      },
      imageLogo: { title: "Logo", placeholder: "Upload logo" },
      imageCashRegister: {
        title: "Kassa-afbeelding",
        placeholder: "Upload kassa-afbeelding",
      },
      theme: {
        title: "Thema",
        primaryColor: "Primair kleur",
        lightColor: "Lichte kleur",
        darkColor: "Donkere kleur",
        ctaButtonColor: "Actie kleur",
        searchButtonColor: "Zoekknop kassa",
      },
    },
    headings: {
      address: {
        title: "Adres",
        description: "Gegevens worden getoond op de bon",
      },
      vouchers: {
        title: "Toegestane vouchers",
      },
      legalAge: {
        title: "Leeftijdscontrole",
        description: "a.h.v een QR-code, vraag om een legitimatiebewijs bij aankoop van alcohol",
      },
      theme: {
        title: "Vormgeving",
      },
    },
    errors: {
      invalidTheme: "Geen geldige kleurcode",
      invalidName: "Minimale lengte is 3 karakters",
      invalidCode: "Minimale lengte is 3 karakters",
      invalidZipCode: "Geen geldige postcode",
      invalidCity: "Geen geldige plaats",
      invalidAddress: "Geen geldig adres",
      invalidVat: "Geen geldig BTW-nummer",
      invalidCoc: "Geen geldig KvK-nummer",
      extIdTaken: "Code is al bezet",
    },
    notifications: {
      created: {
        title: "Locatie aangemaakt",
        message: "Locatie '{{name}}' is aangemaakt",
      },
      updated: {
        title: "Locatie aangepast",
        message: "Locatie '{{name}}' is aangepast",
      },
      deleted: {
        title: "Locatie verwijderd",
        message: "Locatie '{{name}}' is verwijderd",
      },
    },
  },
  report: {
    report: "Rapportage",
    turnoverLocation: "Rapportage {{location}}",
    totalTurnedOver: "Totale omzet",
    averageSpending: "Gemiddelde besteding",
    totalProducts_one: "{{count}} product",
    totalProducts_other: "{{count}} producten",
    averageTransactionTotal: "{{value}} producten",
    totalDepositInclVat: "Waarvan statiegeld: {{value}}",
    soldProducts: "Verkochte producten",
    receiptCount: "Aantal bonnen",

    // Category
    categoriesTitle: "Categorieën",
    categoryName: "Categorienaam",
    categorySold: "Verkocht",
    category: "Categorie",
    turnover: "Omzet",
    categoryHelper: "De eerste ingestelde categorie van een product.",
    // Product
    productTitle: "Producten",
    productName: "Productnaam",
    sold: "Verkocht",
    shopPrice: "Shopprijs",

    // Betaling
    transactionTitle: "Betalingen",
    paymentMethod: "Betaalmethode",
    used: "Gebruikt",
    amount: "Bedrag",

    // Vouchers
    vouchersTitle: "Vouchers",
    voucherName: "Vouchernaam",
    applied: "Ingezet",

    // Globaal
    turnoverTitle: "Omzet",
    location: "Locatie",
    shop: "Shop",
    rating: "Beoordeling",
    shops: "Shops",
    viewMore: "Toon meer {{item}}",
    viewLess: "Toon minder {{item}}",

    errorLoadingContent: "⚠️ Fout in content ⚠️",
  },
  priceLabels: {
    fetchLabels: "Prijskaartjes ophalen",
    labelCount: "Digitale prijskaartjes: <0>{{ count }}</0>",
    notLinkedCount: "Niet gekoppelde producten: <0>{{ count }}</0>",
    linkProducts: "Producten koppelen",
    label: "Digitaal prijskaartje",
    product: "Productnaam",
    shopPrice: "Shopprijs",
    batteryLevel: "Batterij %",
    noAvailable: "Er zijn geen digitale prijskaartjes actief of beschikbaar in deze lijst.",
    updated: "Prijskaartje is aangepast",
    updatedMessage: "{{ name }} is aangepast",
    connectionStatus: "Connectie",
    linked: "Producten zijn gekoppeld",
    linkedMessage: "Alle prijskaartjes zijn aan een willekeurig prijskaartje gekoppeld",
    linkedError: "Producten niet gekoppeld",
    linkedErrorMessage: "Er is een probleem opgetreden tijdens het koppelen van de producten",
    updatedError: "Prijskaartje niet aangepast",
    updatedErrorMessage: "Er is een probleem opgetreden tijdens het aanpassen van het prijskaartje",
    serverNameTitle: "Server naam ingesteld",
    serverNameMessage: "De server naam is ingesteld",
    serverNameError: "Server naam niet ingesteld",
    serverNameErrorMessage:
      "Er is een probleem opgetreden tijdens het instellen van de server naam",
    tableTab: "Overzicht",
    inputTab: "Toevoegen",
    setupTab: "Instellen",
    dynamicTab: "Dynamische prijs",
    idInput: { title: "Prijskaartje ID:", placeholder: "ID" },
    save: "Prijskaartje opslaan",
    saved: "Het prijskaartje {{label}} is succesvol toegevoegd.",
    ipAddress: "IP-adres",
    port: "Poort",
    configPort: "Config poort",
    serverName: "Server naam",
    setupButton: "Instellen",
    priceIncrease: "Prijsverhoging",
    priceIncreaseDescription:
      "Alle prijzen worden afgerond per 5 cent. Bijv: €2,37 wordt afgerond naar €2,40.",
    exceptions: "Uitzonderingen",
    exceptionsDescription:
      "Alle ingestelde uitzonderingen overschrijven eventuele andere ingestelde prijsverhogingen.",
    addException: "Voeg uitzondering toe",
    updatedDynamicPricing: "Dynamische prijzen aangepast",
    updatedDynamicPricingMessage: "De instellingen voor dynamische prijzen zijn aangepast",
    updatedDynamicPricingError: "Dynamische prijzen niet aangepast",
    updatedDynamicPricingErrorMessage:
      "Er is een probleem opgetreden tijdens het aanpassen van de instellingen voor dynamische prijzen",
    createdDynamicDate: "Dynamische prijs uitzondering toegevoegd",
    createdDynamicDateMessage: "De uitzondering voor dynamische prijzen is toegevoegd",
    createdDynamicDateError: "Dynamische prijs uitzondering niet toegevoegd",
    createdDynamicDateErrorMessage:
      "Er is een probleem opgetreden tijdens het toevoegen van de uitzondering voor dynamische prijzen",
    updatedDynamicDate: "Dynamische prijs uitzondering aangepast",
    updatedDynamicDateMessage: "De uitzondering voor dynamische prijzen is aangepast",
    updatedDynamicDateError: "Dynamische prijs uitzondering niet aangepast",
    updatedDynamicDateErrorMessage:
      "Er is een probleem opgetreden tijdens het aanpassen van de uitzondering voor dynamische prijzen",
    deletedDynamicDate: "Dynamische prijs uitzondering verwijderd",
    deletedDynamicDateMessage: "De uitzondering voor dynamische prijzen is verwijderd",
    deletedDynamicDateError: "Dynamische prijs uitzondering niet verwijderd",
    deletedDynamicDateErrorMessage:
      "Er is een probleem opgetreden tijdens het verwijderen van de uitzondering voor dynamische prijzen",
  },
  reviews: {
    title: "Beoordelingen",
    datetime: "Datum en tijd",
    location: "Locatie",
    rating: "Beoordeling",
    transaction: "Transactie",
    message: "Opmerking",
  },
  dateTime: {
    start: "Begintijd",
    end: "Eindtijd",
    invalid: "Ongeldige selectie",
    invalidBefore: "eindtijd is voor starttijd",
    previousMonth: "Vorige maand",
  },
  successPage: {
    scanQr: "Scan de QR-code of typ uw e-mailadres voor een PDF van uw bon.",
    sendReceipt: "Verstuur bon",
    fillInEmailForReceipt: "Wilt u de bon digital ontvangen? Vul hieronder uw e-mailadres in.",
    emailError: "Er is een fout opgetreden, probeer het opnieuw.",
    emailSent: "Verzonden",
  },
  components: {
    dateRangeSelector: {
      [DateRangeOption.DAY]: "Vandaag",
      [DateRangeOption.WEEK]: "Deze week",
      [DateRangeOption.MONTH]: "Deze maand",
      [DateRangeOption.YEAR]: "Dit jaar",
      [DateRangeOption.CUSTOM]: "Aangepast",
    },
  },
  voucher: {
    voucherReport: "Voucher rapportage",
    amountScanned: "Aantal gescande QR's",
    amountRedeemed: "Aantal verzilverde items",
    extraRevenue: "Extra omzet",
    givenAmount: "Weggegeven bedrag",
    productName: "Productnaam",
    sold: "Verkocht",
    turnover: "Omzet",
    deposit: "Statiegeld",
    soldItems: "Verzilverde items",
    total: "Totaal",
  },
};
