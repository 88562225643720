export interface DataResponse<T> {
  data: T;
}

export enum NotificationColors {
  info = "blue",
  success = "green",
  warning = "yellow",
  error = "red",
}

export enum DefaultTheme {
  primaryColor = "#277A99",
  lightColor = "#609CB3",
  darkColor = "#1B556B",
  ctaButtonColor = "#F7933B",
  searchButtonColor = "#1B526B",
  startscreenImageCdnUrl = "https://cdn.bfldr.com/BLW26VG3/at/tps9fbgp4tpqg32btxfvs4h/kassafoto.png?auto=webp&format=jpg",
}

export enum Environment {
  local = "LOCAL",
  staging = "STAGING",
  production = "PRODUCTION",
}

export interface AllowedProperty {
  id: number;
  name: string;
  slug: string;
  theme: Theme;
  validateLegalAge: boolean;
}

export type User = {
  id: number;
  email: string;
  password?: string;
  group: Group;
  role: UserRoles;
  allowedProperties: AllowedProperty[];
  lastSeen: string | null;
};

export type Group = {
  id: number;
  name: string;
  slug: string;
};

export type Property = {
  id: number;
  groupId?: number;
  name: string;
  slug: string;
  voucherConfig: VoucherType[];
  shops: Shop[];
  extId: string;
  extVatId: string;
  extChamberOfCommerceId: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  logoUrl: string;
  logoCdnUrl: string;
  vouchersEnabled: boolean;
  validateLegalAge: boolean;
  theme: Theme;
};

export enum VoucherType {
  Welcome = "valk_voucher",
  Employee = "employee_discount",
}

export type CombiDeal = {
  id: number;
  translations: Translation[];
  groupId: number;
  priceCents: number;
  assignedProperties: CombiDealProperty[];
  productGroupA: CombiDealProduct[];
  productGroupB: CombiDealProduct[];
};

export type CreateCombiDeal = Omit<CombiDeal, "id">;

export type CombiDealProduct = {
  productId: number;
};

export type CombiDealProperty = {
  propertyId: Property["id"];
};

export type Theme = {
  primaryColor: string;
  lightColor: string;
  darkColor: string;
  ctaButtonColor: string;
  searchButtonColor: string;
  startscreenImageCdnUrl: string;
};

export type PropertyCreate = Omit<Property, "id">;

export type UpdateCategory = Category & {
  groupId: number;
};

export type Category = {
  id: number;
  imageUrl: string;
  imageCdnUrl: string;
  isGroupCategory?: boolean;
  productIds: number[];
  property?: Pick<Property, "id" | "name">;
  propertyId?: Property["id"];
  translations: Translation[];
};

export type Shop = {
  id: number;
  propertyId?: number;
  name: string;
  receiptName: string;
  language: Language;
  type: "take_out" | "pickup";
  orderingEmailAddresses: string[];
  replyToEmailAddress: string;
  url: string;
  priceLabelConfig: Pick<PriceLabelConfig, "hostIpAddress" | "hostPort" | "hostConfigPort">;
  shortcuts: Shortcut[];
  categoryConfigs: CategoryConfig[];
};

export type PriceLabelConfig = {
  hostIpAddress: string;
  hostPort: string;
  hostPortStatus: string;
  hostConfigPort: string;
  hostConfigPortStatus: string;
  configUrl: string;
  serverName: string;
};

export type CategoryConfig = {
  categoryId: number;
  categoryOrder: number;
  visible?: boolean;
};

export type Report = {
  averagePerTransaction: {
    productCount: number;
    revenueInclVat: number;
  };
  reviewRatingAverage: number;
  products: ReportProduct[];
  categories: ReportCategory[];
  transactions: ReportTransaction[];
  totalsPerProperty: ReportPropertyTotals[];
  totalDepositInclVat: number;
  totalProductCount: number;
  totalRevenueInclVat: number;
  totalReceiptCount: number;
};

export type ReportProduct = {
  id: number;
  property_id: number | null;
  retailPriceInclVat: number;
  shopPriceInclVat: number;
  timesSoldCount: number;
  translations: Translation[];
  turnoverInclVat: number;
};

export type ReportCategory = {
  id: number;
  propertyId: number | null;
  translations: Translation[];
  timesSoldCount: number;
  turnoverInclVat: number;
};

export type ReportTransaction = {
  method: string;
  timesMethodUsedCount: number;
  amountCents: number;
};
export type ReportPropertyTotals = {
  id: number;
  name: string;
  slug: string;
  productCount: number;
  receiptCount: number;
  revenueInclVat: number;
  reviewRatingAverage: number;
  shops: Omit<ReportPropertyTotals, "shops">[];
};

export type CashRegister = {
  id: number;
  extTerminalId: string;
  merchant: string;
  model: RegisterModel;
  userAgent?: string;
  shopId?: number;
};

export enum RegisterModel {
  TABLE = "table",
  FLOOR = "floor",
}

export type TranslationLink = {
  translations: Translation[];
  link?: string;
  labels?: {
    concept: boolean;
    location: string | undefined;
    ownProduct: boolean;
  };
};

export type CashRegisterCreate = {
  cashRegister: Omit<CashRegister, "id">;
};

export type Shortcut = {
  order: number;
  productId: number;
};

export type ShopCreate = {
  shop: Pick<
    Shop,
    | "name"
    | "receiptName"
    | "language"
    | "priceLabelConfig"
    | "shortcuts"
    | "propertyId"
    | "categoryConfigs"
  >;
};

export enum Language {
  nl = "nl",
  en = "en",
  de = "de",
}

export enum Country {
  de = "Duitsland",
  nl = "Nederland",
}

export enum ProductState {
  concept = "concept",
  published = "published",
  archived = "archived",
}

export type ProductResult = {
  meta: MetaResponse;
  data: Product[];
};

export type Product = {
  id: number;
  retailPriceCents: number;
  depositCents: number | null;
  eanCode: string;
  imageUrl: string;
  imageCdnUrl: string;
  ageCheck: boolean;
  propertyId: number | null;
  groupId: number;
  ledgerAccountId: number;
  translations: Translation[];
  state: ProductState;
  categoryIds: productCategory[];
  propertyConfigs: PropertyConfig[];
  archivedAt?: string | null;
};

export type MetaResponse = {
  totalCount: number;
  totalPages: number;
};

type TurnOverFields = LedgerDetails & {
  amountExclVat: number;
  amountInclVat: number;
  vatAmount: number;
};
interface LedgerDetails {
  ledgerAccountDescription: string;
  ledgerAccountExtId: string;
}
type TransactionFields = LedgerDetails & {
  amount: number;
};
export interface Turnover {
  transactions: TransactionFields[];
  revenues: TurnOverFields[];
}

type productCategory = {
  categoryOrder: number;
  categoryId: number;
};

export type CreateProduct = Partial<Omit<Product, "propertyConfigs" | "categoryIds">> & {
  propertyConfigs: Partial<PropertyConfig>[];
  categories: { categoryOrder: number; categoryId: number }[];
};

type CountryCode = "nl" | "de";

export type Vat = {
  id: number;
  country: CountryCode;
  percentage: number;
};

export type LedgerAccount = {
  id: number;
  extId: string;
  description: string;
  extVatCode: string;
  vatId: string;
  type: "product" | "deposit";
  propertyId: number | undefined;
  property?: Pick<Property, "id" | "name" | "extId">;
};

export type PropertyConfig = {
  id?: number;
  propertyId: number;
  customPriceCents: number | null;
  purchasable: boolean;
  ledgerAccountId: number;
  shopConfigs: Partial<ShopConfig>[];
  ledgerAccount?: LedgerAccount;
};

export type ProductOverview = {
  ageCheck: boolean;
  amountOfShops: number;
  archivedAt: string | null;
  categoryIds: productCategory[];
  eanCode: string;
  groupId: number;
  id: number;
  imageUrl: string | null;
  imageCdnUrl: string | null;
  ledgerAccountId: number;
  propertyId: number | null;
  retailPriceCents: number;
  depositCents: number | null;
  state: ProductState;
  translations: Translation[];
  propertyConfigs: PropertyConfig[];
  customPriceCents: number;
};
export type CombiDealsOverview = {
  id: number;
  priceCents: number;
  translations: Translation[];
  assignedProperties: CombiDealProperty[];
  propertyId: number | null;
  groupId: number;
};

export type ShopConfig = {
  id: number;
  shortcut: boolean;
  visible: boolean;
  shortcutOrder: number;
  shopId: number;
};

export type Translation = {
  language: Language;
  name: string;
  tags?: string[];
  description?: string;
};

export type TranslationKey = { [key in Language]: string };

export type Customer = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
};

export type Receipt = {
  id: string;
  ref: string;
  paidAt: string | null;
  createdAt: string | null;
  failedAt: string | null;
  methods: string;
  shop: ShopOnReceipt;
  amountCents: number;
  productTotalAmountCents: number;
  transactionTotalAmountCents: number;
  depositTotalAmountCents: number;
  recipients: {
    email: string;
    sentAt: string;
  }[];
  lineItems: LineItem[];
  review?: Review;
  state: ReceiptState;
  hasLegalAge: boolean;
  legalAgeCheck: boolean | null;
  accountNumber: string;
  transactions: TransactionField[];
  type: "take_out" | "pickup";
  pickupStartAt: string;
  customer: Customer;
};

export type ReviewList = {
  reviews: {
    transactionId: string;
    paidAt: string | null;
    createdAt: string | null;
    property: {
      id: number;
      name: string;
    };
    review: Review;
    shop: ShopOnReceipt;
  }[];
  ratingAverage: number;
};

export type TransactionField = {
  id: number;
  amountCents: number;
  cardNr: string;
  failedAt: string | null;
  meta: any;
  method: string;
  paidAt: string | null;
  ref: string;
  state: ReceiptState;
  type: string;
};
type ShopOnReceipt = Pick<Shop, "name" | "receiptName">;
type VoucherMeta = {
  addedAt: string;
  discountType: string;
  extId: string;
  quantity: number;
};
type TransactionMeta = {
  isTurnover?: boolean;
  voucher?: VoucherMeta;
};
type Review = {
  rating: number;
  message?: string;
};
type LineItem = {
  id: number;
  productId: number;
  receiptId: number;
  priceCents: number;
  depositCents: number;
  quantity: number;
  translations: Translation[];
  meta: TransactionMeta;
  type: string;
};
export enum ReceiptState {
  PROCESSING = "PROCESSING",
  CREATED = "CREATED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export enum UserRoles {
  default = "default",
  admin = "admin",
  manager = "manager",
}

export type DepositOption = {
  country: string;
  amountCents: number;
  name: string;
};

export type Permissions = {
  account: PermissionTypes;
  category: PermissionTypes;
  product: PermissionTypes;
  location: PermissionTypes;
  shop: PermissionTypes;
  administration: PermissionTypes;
  report: PermissionTypes;
  transaction: PermissionTypes;
  qrcode: PermissionTypes;
  voucher: PermissionTypes;
  reviews: PermissionTypes;
  digitalPriceLabels: PermissionTypes;
  priceLabelConfig: PermissionTypes;
  pickup: PermissionTypes;
};

export type LedgerAccountCreate = {
  ledgerAccount: {
    groupId: number;
  } & Omit<LedgerAccount, "id">;
};
export type PermissionTypes = {
  read: UserRoles[];
  write: UserRoles[];
  delete: UserRoles[];
};

export type PaymentMethodOptions = Record<string, string>;

export interface PaymentMethod {
  description: string;
  method: string;
  id: number;
  ledgerAccountExtId: string;
  extVatCode: string;
}

export interface VoucherProduct {
  id: number;
  propertyId: number | null;
  deductedAmountInclVat: number;
  deductedDepositInclVat: number;
  timesDeductedCount: number;
  translations: Translation[];
}

export interface VoucherTotal {
  deductedAmountInclVat: number;
  deductedProductCount: number;
  deductedDepositInclVat: number;
  receiptCount: number;
  receiptRevenueInclVat: number;
  voucherCountUnique: number;
}

export interface VoucherReport {
  products: VoucherProduct[];
  totals: VoucherTotal;
}

export type CategoryReport = {
  category: ReportCategory;
  products: ReportProduct[];
};

export interface PriceLabelSettings {
  priceLabels: PriceLabel[];
  unassignedProductCount: number;
}
export interface PriceLabel {
  deviceId: string;
  productId: Product["id"] | null;
  productName: string | null;
  registeredToGatewayAt: string | null;
  batteryLevel: string | null;
  state: "disconnected" | "connected";
}

export interface DynamicHours {
  isEnabled: boolean;
  dayOfWeek: number;
  startTime: string;
  endTime: string;
}

export interface DynamicPricing {
  dynamicPercentage: number;
  dynamicHoursList: DynamicHours[];
}

export interface DynamicDate {
  id: number;
  percentage: number;
  startAt: string;
  endAt: string;
}

export interface GetPaymentMethodResponse {
  paymentMethodOptions: PaymentMethodOptions;
  paymentMethods: PaymentMethod[];
}

export interface UpdatePaymentMethod extends PaymentMethod {
  propertyId: Property["id"];
}

export type CreatePaymentMethod = Omit<PaymentMethod, "id">;

export interface UpdatePriceLabel {
  product: Pick<Product, "id" | "eanCode">;
  labelId: string;
  shopId: Shop["id"];
}

export interface AddPriceLabel {
  labelId: string;
  shopId: Shop["id"];
}

export enum DateRangeOption {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  CUSTOM = "custom",
}

export interface Pagination {
  pageNumber?: number;
  pageSize?: number;
  filter?: string;
  orderBy?: string;
  orderDirections?: string;
}

export enum SortingOrder {
  ASC = "asc",
  DESC = "desc",
}
